@import '../Variables.scss';
@import '../Mixins.scss';

.hero.interior-hero {
  .hero-image {
    object-position: center;
  }

  .hero-content {
    padding: 75px 0 34px;

    @include tablet {
      padding: 60px 0 34px;
    }

    @include laptop {
      padding: 80px 0 34px;
    }
  }
}

.hero {
  position: relative;
  background-color: $brand-secondary;

  &::after {
    content: '';
    background: linear-gradient(to right, #000000b8 14%, rgb(0 0 0 / 27%));
    position: absolute;
    z-index: 0;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // backdrop-filter: blur(2px);
  }

  // &::before {
  //   content: '';
  //   background: linear-gradient(90deg, rgba(0, 0, 0, 0.631372549) 39%, #00000054);
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   width: 85%;
  //   height: 100%;
  //   object-fit: cover;
  //   z-index: 1;
  // }

  .container-arrows-down {
    position: relative;
    z-index: 3;

    .arrows-down {
      position: absolute;
      right: 35px;
      bottom: -20px;
      width: 30px;

      @include tablet {
        bottom: -30px;
        width: 50px;
      }
    }
  }

  .hero-image {
    position: absolute;
    z-index: 0;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-content {
    padding: 120px 0 100px;
    position: relative;
    z-index: 1;

    .outline-text {
      /* Faux outline for older browsers */
      color: white;
      /* Unfortunately you can't use transparent here … */
      text-shadow:
        -1px -1px 0 white,
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white;

      /* Real outline for modern browsers */
      @supports((text-stroke: 2px white) or (-webkit-text-stroke: 2px white)) {
        color: transparent;
        text-stroke: 2px white;
        text-shadow: none;
        -webkit-text-stroke: 1px white;

        @include tablet {
          -webkit-text-stroke: 2px white;
        }
      }
    }

    .line-1,
    .line-2,
    .line-3,
    .line-4 {
      text-transform: uppercase;
      margin: 0;
      line-height: 1;
    }

    .line-1 {
      color: white;
      font-weight: 300;
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 22px;
      margin-left: 2px;
      font-size: 16px;

      @include tablet {
        margin-bottom: 32px;
      }

      @include laptop {
        font-size: 20px;
      }

      &::after {
        content: '';
        background-color: $brand-primary;
        width: 12%;
        height: 2px;
      }
    }

    .line-2 {
      font-family: $michroma;
      letter-spacing: 3px;
      font-size: calc(3rem + 4.5*((100vw - 42rem)/81));
      font-family: "Orbitron", sans-serif;
      margin-bottom: 10px;

      @include tablet {
        font-weight: 900;
        font-size: 50px;
      }

      @include laptop {
        font-size: 80px;
      }

      @include desktop {
        font-size: 82px;
      }
    }

    .line-3 {
      color: white;
      font-weight: 600;
      margin-bottom: 8px;
      font-size: calc(3rem + 4.5*((100vw - 42rem)/81));
      letter-spacing: 2px;
      font-weight: 900;
      font-family: "Orbitron", sans-serif;

      @include tablet {
        font-size: 60px;
      }

      @include laptop {
        font-size: 70px;
      }

      @include desktop {
        font-size: 82px;
      }
    }

    .line-4 {
      font-weight: 900;
      letter-spacing: 3px;
      font-family: $michroma;
      font-size: calc(3rem + 4.5*((100vw - 42rem)/81));
      font-family: "Orbitron", sans-serif;

      @include tablet {
        font-size: 50px;
      }

      @include laptop {
        font-size: 80px;
      }

      @include desktop {
        font-size: 82px;
      }
    }

    .hero-buttons {
      display: flex;
      margin-top: 30px;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px;

      @include tablet {
        column-gap: 50px;
      }

      a {
        color: #ffba32;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 16px;
        position: relative;
        display: flex;
        column-gap: 10px;

        @include tablet {
          font-weight: 600;
        }

        &:hover {
          color: white;
        }

        &::after {
          content: '';
          position: relative;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 14px;
          height: 14px;
          background-image: url('/../public/white-arrows.webp');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .social-media-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    font-weight: 800;
    background: black;
    height: fit-content;
    align-items: center;
    background: rgb(133 133 133 / 20%);
    backdrop-filter: blur(3px);
    padding: 22px 0 100px 0;
    display: none;
    z-index: 1;

    @include tablet {
      display: flex;
    }

    @include laptop {
      padding: 35px 00px 120px 0px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffba32;
      font-size: 16px;

      @include tablet {
        font-size: 20px;
      }

      @include laptop {
        font-size: 30px;
      }

      .social-link-text {
        font-family: $primary-font;
        font-weight: 500;
        transform: rotate(90deg);
        top: 45px;
        position: relative;
        display: block;
        width: 100%;
        color: white;
        font-size: 16px;

        @include laptop {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
}

.strapline {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.736);
  padding: 25px 0;

  p {
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;

    @include tablet {
      font-size: 20px;
    }

    span::before {
      content: '|';
      margin: 0 20px;
      color: white;
    }
  }
}

.hero-skeleton {
  position: relative;
  background-color: rgb(245, 245, 245);
  padding: 0 0 100px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .skeleton-image {
    object-fit: cover;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; // Matches light gray in hero
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .container {
    position: relative;
    z-index: 2;
    padding: 0 20px 0 100px;

    .skeleton-text {
      background-color: #e0e0e0;
      border-radius: 4px;
      margin-bottom: 10px;
      animation: shimmer 1.5s infinite linear;
    }

    .skeleton-button {
      background-color: rgba($brand-primary, 0.5); // 50% opacity
      border-radius: 4px;
      margin: 5px;
      animation: shimmer 1.5s infinite linear;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -100px 0;
  }

  100% {
    background-position: 100px 0;
  }
}

.hero-skeleton.interior-hero-skeleton {
  position: relative;
  background-color: rgb(245, 245, 245);
  padding: 60px 0;
  overflow: hidden;
  min-height: 20vh;
  display: flex;
  align-items: center;

  .skeleton-image {
    object-fit: cover;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; // Matches light gray in hero
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .container {
    position: relative;
    z-index: 2;
    padding: 0 20px 0 100px;
  }
}