@import '../Variables.scss';
@import '../Mixins.scss';

.copyright {
  background-color: rgb(18, 18, 18);
  text-align: center;
  color: rgb(243, 243, 243);
  padding: 10px 0;
  font-size: 12px;

  .client-copy,
  .dev-copy {
    display: block;

    @include phablet {
      display: inline-block;
    }
  }

  .divider {
    display: none;
    margin: 0 5px;

    @include phablet {
      display: inline-block;
    }
  }

  a {
    color: white;
    text-decoration: underline !important;
    text-underline-offset: 3px;
    text-decoration-color: rgb(146 146 146) !important;
    text-decoration-thickness: 0.5px !important;
  }
}

.footer {
  background-color: $brand-secondary;
  color: whitesmoke;
  padding: 50px 0;
  font-size: 16px;

  a {
    color: whitesmoke;

    &:hover {
      color: $brand-primary;
    }
  }

  .footer-info-row {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin: 20px 0;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }

  .hours,
  .phone {
    display: block;
    width: 100%;
    margin: 0 0 20px;

    @include desktop {
      margin: 0;
      width: fit-content;
      display: inline-block;
    }
  }

  .footer-social {
    margin-top: 14px;

    @include desktop {
      margin-top: 0;
    }
  }

  .nav {
    column-gap: 50px;
    row-gap: 15px;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    li {
      position: relative;
      z-index: 1;
      width: fit-content;
      margin: 0 auto;

      @include tablet {
        margin: 0;
      }

      a {
        color: #fff9ec;

        &::after {
          content: '';
          position: absolute;
          inset: 0;
          margin: auto;
          background-color: #4c5600;
          height: 7px;
          width: 0;
          left: -5px;
          z-index: -1;
          opacity: 0;
          transition: 250ms ease-in-out;
        }

        &:hover {
          &::after {
            opacity: 1;
            width: calc(100% + 10px);
          }
        }

        &.active {
          color: #fff9ec;

          &::after {
            opacity: 1;
            width: calc(100% + 10px);
          }
        }
      }
    }
  }
}