@import '../Variables.scss';
@import '../Mixins.scss';

.faqs-title {
  // padding: 0 200px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.accordion {
  // padding: 0 200px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .accordion-item {
    border-radius: 0 !important;
    border: 1px solid $brand-primary !important;

    .accordion-button {
      border-radius: 0 !important;

      &:focus {
        // background-color: none;
        box-shadow: none !important;
      }

      &:not(.collapsed) {
        background-color: lighten($brand-primary, 30%);
        background-color: $brand-primary;
        color: black;
      }
    }

    .accordion-collapse {
      // border-radius: 0 !important;
    }
  }
}