@import '../Variables.scss';
@import '../Mixins.scss';

.testimonials-section {
  background-color: #f8f8f8;
  position: relative;

  &::after {
    content: '';
    background-image: url('/US-Flag.webp');
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto 0;
    opacity: 0.04;
    background-attachment: fixed;
    transform: scaleX(-1);
    width: 90%;

    @include laptop {
      width: 50%;
    }
  }
}

.testimonials {
  position: relative;
  z-index: 1;
  padding: 120px 0 60px;

  @include laptop {
    padding: 100px 0 60px;
  }

  i.fa-quote-left {
    top: -100px;
    color: #F0F0F0;
    position: absolute;
    left: 20px;
    top: -90px;
    filter: drop-shadow(6px 6px 0 rgba(93, 93, 93, 0.3));
    font-size: 150px;

    @include laptop {
      font-size: 210px;
    }
  }

  >.row {
    margin: 0 12px;

    @include laptop {
      padding: 0 15px;
    }
  }

  .col-testimonial-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;

    @include laptop {
      top: -55px;
      margin-bottom: 0;
    }

    h2 {
      font-size: 50px;
      line-height: 1;
      z-index: 1;
      font-weight: 700;
      padding: 0;

      @include laptop {
        padding: 0 0 0 150px;
      }

      .review-us-btn {
        font-size: 16px;
        margin-top: 10px;

        &::after {
          background-image: url('/../public/arrows-down.webp');
        }
      }
    }
  }

  .swiper {
    @include wide {
      width: 700px;
    }
  }

  .feature {
    @include laptop {
      padding: 0 20px;
    }
  }

  .testimonial-swiper {
    .testimonial-name {
      text-align: right;
      justify-content: flex-start;
      flex-direction: row-reverse;
      align-items: center;
      column-gap: 20px;
      position: relative;
      display: flex;
      font-size: 20px;

      &::before {
        content: '';
        background-color: $brand-primary;
        width: 60px;
        height: 2px;
      }
    }

    .read-more {
      color: #8A5C00;
      transition: 200ms ease-in-out;
      margin-left: 5px;

      &:hover {
        color: $brand-primary;
        cursor: pointer;
      }
    }
  }

  .testimonials-arrow-slider {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .testimonnial-swiper-button-next,
  .testimonnial-swiper-button-prev {
    background-color: rgba(0, 0, 0, 0);
    color: #515151;
    border: none;
    padding: 0 10px;
    height: fit-content;
    line-height: 1;
    cursor: pointer;
    position: relative;
    font-size: 30px;

    @include tablet {
      font-size: 40px;
    }

    &::after {
      display: none;
    }
  }

  .testimonnial-swiper-button-prev {
    &::after {}
  }

  .testimonnial-swiper-button-next {}

  .swiper-button-disabled {
    opacity: .4;
  }
}