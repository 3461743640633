@import '../Variables.scss';
@import '../Mixins.scss';

.col-sidebar {
  border-right: 1px solid #ffa5a5;
  transition: margin-right 0.3s ease; // Smooth transition for the sidebar's margin
  width: auto !important;
  margin-right: 2px;
  // padding: 0 48px 0 0;

  @include tablet {
    margin-right: 10px;
  }

  @include laptop {
    margin-right: 40px;
  }

  // @include laptop {
  //   // width: 21% !important;
  //   width: auto !important;
  //   margin-right: 50px;
  //   transition: 200ms ease;
  // }
}

.sidebar {
  height: 100%;
  transition: 200ms ease-in-out;
  position: sticky;
  height: fit-content;
  margin-top: 14px;

  @include laptop {
    margin-right: 20px;
  }

  &.header-is-showing {
    top: 125px;

    @include tablet {
      top: 140px;
    }

    @include laptop {
      top: 170px;
    }
  }

  &.header-is-closed {
    top: 15px;
  }

  /* Add these styles to Sidebar.scss */

  #nav-icon2 {
    width: 34px;
    height: 34px;
    position: relative;
    margin: 0 0 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
}

/* Icon 2 */

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  // border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  // border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 14px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 28px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 14px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 14px;
}

.nav-title {
  margin-bottom: 20px;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  width: 35px;
  padding-left: 4px;
  /* Default width */
  transition: width 0.3s ease, transform 0.3s ease;
  /* Smooth transition for width */
  overflow: hidden;
  /* Hide content when sidebar is collapsed */

  &.open {
    width: 200px; // Set the width for open state
    opacity: 1; // Ensure visibility when open
    overflow: visible;

    a span {
      opacity: 1; // Make the span invisible by default
      // transform: translateX(-10px); // Move it slightly out of view
      transition: opacity 0.3s ease-in-out 0.400ms, transform 1s ease-in-out; // Transition opacity and transform with a delay
    }

    a {
      width: 100%;
    }

    li .tooltip-icon {
      display: inline-block;
    }
  }

  li {
    position: relative;
    transition: transform 0.3s ease;
    /* Add smooth transition for list items */

    .tooltip-icon {
      position: absolute;
      top: -14px;
      cursor: pointer;
      display: none;
      width: fit-content;
      right: 2px;

      @include laptop {
        right: -18px;
      }

      i {
        margin-left: 5px;
        color: #cec9c9;
        font-size: 0.9rem;
      }

      .custom-tooltip {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fbfbfb;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px 14px;
        white-space: nowrap;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        z-index: 10;
        text-align: center;

        a {
          color: black;
          text-decoration: none;
          font-size: 16px;
          font-weight: 600;

          &:hover {
            text-decoration: underline;
            color: #4d3300;
          }
        }
      }
    }

    .sidebar-item {
      position: relative;
    }

    a span {
      display: inline-block; // Make span an inline-block
      // opacity: 0; // Initially hidden
      // transform: translateX(-10px); // Slide it out of view
      // transition: opacity 0.3s ease-in-out 0.3s, transform 0.3s ease-in-out; // Delay for smooth fade-in and slide-in
    }

    a {
      position: relative;
      color: #4d3300;
      display: flex;
      column-gap: 15px;
      align-items: center;
      font-weight: 500;
      height: 34px;
      width: 24px;
      transition: 200ms ease-in-out;
      width: 100vw;

      img {
        width: 26px;
        height: 20px;
        object-fit: contain;
        margin: 0;
        object-position: center;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 25px;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto 0;
        background-color: rgb(255 0 0 / 9%);
        height: 5px;
        width: 0;
        left: -5px;
        z-index: -1;
        opacity: 0;
        transition: 250ms ease-in-out;
      }

      &:hover {
        color: #4d3300;

        &::after {
          opacity: 1;
          width: calc(100% + 10px);
        }
      }

      &.active {
        opacity: 0.3;

        img {
          opacity: 0.3;
        }

        &::after {
          opacity: 0;
          width: calc(100% + 10px);
        }
      }

      &.no-active-effect.active {
        opacity: 1;

        &:hover {
          color: #830000;
        }

        &::after {
          width: 0;
          opacity: 0;
        }

        &:hover::after {
          opacity: 1;
          width: calc(100% + 10px);
        }
      }
    }
  }
}