@import '../Variables.scss';
@import '../Mixins.scss';

.hero.footer-hero {
  background-color: white;
  position: relative;

  &::after {
    display: none;
  }

  .hero-object {
    background-image: url('/Lizotte-Construction-Trucking.webp');
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    margin: auto 0;
    opacity: 0.1;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    bottom: 0;
    margin-left: -100px;
    background-size: 100%;

    @include phablet {
      margin-left: 0;
      background-size: 100%;
    }

    @include tablet {
      background-size: 50%;
    }
  }

  .container-arrows-down {
    position: relative;
    z-index: 3;

    .arrows-down {
      position: absolute;
      bottom: -20px;
      width: 30px;
      left: 0;
      right: 0;
      margin: auto;

      @include tablet {
        bottom: -30px;
        width: 50px;
      }
    }
  }

  .hero-content {
    position: relative;
    z-index: 1;
    padding: 80px 0 50px;

    @include tablet {
      padding: 80px 0;
    }

    @include laptop {
      padding: 100px 0 80px;
    }

    .outline-text {
      /* Faux outline for older browsers */
      color: white;
      /* Unfortunately you can't use transparent here … */
      text-shadow:
        -1px -1px 0 white,
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white;

      /* Real outline for modern browsers */
      @supports((text-stroke: 2px white) or (-webkit-text-stroke: 2px white)) {
        color: transparent;
        -webkit-text-stroke: 2px rgb(0, 0, 0);
        text-stroke: 2px white;
        text-shadow: none;
      }
    }

    .line-1 {
      text-transform: uppercase;
      margin: 0;
      line-height: 1;
      color: rgb(0, 0, 0);
      font-weight: 900;
      font-size: 20px;
      position: relative;
      display: block;
      margin-bottom: 32px;
      margin-left: 2px;
      font-family: $michroma;
      text-align: center;
      font-size: 45px;
      font-family: "Orbitron", sans-serif;

      @include phablet {
        font-size: 65px;
      }

      @include tablet {
        font-size: 75px;
      }

      @include laptop {
        font-size: 95px;
      }

      @include desktop {
        font-size: 90px;
      }

      br {
        display: none;

        @include tablet {
          display: block;
        }
      }

      &::after {
        content: '';
        background-color: $brand-primary;
        width: 12%;
        height: 2px;
      }
    }

    .hero-buttons {
      display: flex;
      column-gap: 50px;
      margin-top: 20px;
      justify-content: center;

      @include laptop {
        margin-top: 30px;
      }

      a {
        color: #4d3300;
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        column-gap: 10px;
        position: relative;

        &:hover {
          color: $brand-primary;
        }

        &::after {
          content: '';
          position: relative;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 14px;
          height: 14px;
          background-image: url('/../public/arrows-down.webp');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.hero-skeleton {
  position: relative;
  background-color: rgb(245, 245, 245);
  padding: 0 0 100px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .skeleton-image {
    object-fit: cover;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; // Matches light gray in hero
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .container {
    position: relative;
    z-index: 2;
    padding: 0 20px 0 100px;

    .skeleton-text {
      background-color: #e0e0e0;
      border-radius: 4px;
      margin-bottom: 10px;
      animation: shimmer 1.5s infinite linear;
    }

    .skeleton-button {
      background-color: rgba($brand-primary, 0.5); // 50% opacity
      border-radius: 4px;
      margin: 5px;
      animation: shimmer 1.5s infinite linear;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -100px 0;
  }

  100% {
    background-position: 100px 0;
  }
}

.hero-skeleton.interior-hero-skeleton {
  position: relative;
  background-color: rgb(245, 245, 245);
  padding: 60px 0;
  overflow: hidden;
  min-height: 20vh;
  display: flex;
  align-items: center;

  .skeleton-image {
    object-fit: cover;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; // Matches light gray in hero
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .container {
    position: relative;
    z-index: 2;
    padding: 0 20px 0 100px;
  }
}