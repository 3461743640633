@import '../Variables.scss';
@import '../Mixins.scss';

.review-us-container {
  margin-top: 160px;

  @include laptop {
    margin-top: 200px;
  }

  @include desktop {
    margin-top: 220px;
  }

  i {
    font-size: 40px;
    padding-right: 0.1rem;
    color: #ffc200;
  }

  .filled {
    color: #ffc200;
  }


  .nav-tabs {
    justify-content: center;
    border: none;

    .nav-link {
      border: none;
    }

    .smiley-satisfied {
      transition: 200ms ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }

    .smiley-dissatisfied {
      opacity: 0.07;
    }

    .smiley-satisfied,
    .smiley-dissatisfied {
      display: inline-block;
      margin: 0 10px 30px;
      width: 140px;

      @include tablet {
        width: 200px;
      }
    }

    .fa-face-smile,
    .fa-face-frown {
      font-size: 150px;
      color: rgb(26, 26, 26);
      transition: 200ms ease-in-out;
      margin-bottom: 30px;

      &:hover {
        color: rgb(72, 72, 72);
      }
    }

    .nav-link.active {
      i {
        color: rgb(72, 72, 72);
      }
    }
  }

  .message {
    background-color: #234878;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 30px;
    width: fit-content;
    display: block;
    margin: 0 auto 2rem;
  }

}

.custom-rating {
  display: flex;
  justify-content: center;
  column-gap: 8px;

  i {
    font-size: 30px;

    @include tablet {
      font-size: 50px;
    }
  }
}