@import '../Variables.scss';
@import '../Mixins.scss';

.banner {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: transform 0.3s ease-in-out, opacity 0.4s ease-in-out;
  box-shadow: 0 1px 1px 0px whitesmoke;
  width: 100vw;

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }

  .top-nav::after {
    content: '';
    background-color: $nav-top-bg;
    position: absolute;
    z-index: -1;
    width: 100000px;
    height: 100%;
    object-fit: cover;
    left: -100vw;
    top: 0;
  }

  .top-nav-container {
    max-width: 100vw;
    padding: 0;
  }

  .top-nav {
    position: relative;
    column-gap: 35px;
    padding: 6px 0;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    word-break: 100vw;

    li {
      a {
        color: rgb(222, 222, 222);
        padding: 6px 0;
        display: inline-block;

        @include laptop {
          padding: 12px 0;
        }

        &:hover,
        &.active {
          color: $brand-primary;
        }
      }
    }
  }

  .logo-container {
    display: flex;
    align-items: center;

    a {
      position: relative;
      top: 25px;

      @include laptop {
        top: 31px;
      }

      .logo {
        width: 500px;

        @include laptop {
          width: 300px;
        }

        @include desktop {
          // width: 254px;
          width: 500px;
        }
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      justify-content: flex-end;
      list-style: none;

      li {
        position: relative;
        z-index: 1;

        a,
        .menu-item {
          font-size: 16px;
        }
      }
    }

    .primary-nav.aos-complete [data-aos] {
      opacity: 1 !important;
      transform: none !important;
    }

    .primary-nav {
      column-gap: 10px;
      padding: 0px 0;
      align-items: center;

      @include laptop {
        column-gap: 24px;
      }

      @include desktop {
        padding: 15px 0;
      }

      @include wide {
        column-gap: 50px;
      }

      li {
        display: inline-block;

        a,
        .menu-item {
          color: black;
          display: none;
          text-transform: uppercase;
          letter-spacing: 1px;
          position: relative;

          @include laptop {
            display: block;
          }


          &::after {
            content: '';
            position: absolute;
            margin: auto;
            background-color: #ffdb94;
            height: 5px;
            bottom: -5px;
            width: 0;
            left: 0;
            z-index: -1;
            opacity: 0;
            transition: 250ms ease-in-out;
          }

          &:hover {
            &::after {
              opacity: 1;
              width: 100%;
            }
          }

          &.active {
            color: #4d3300;

            &::after {
              opacity: 1;
              width: 100%;
            }
          }
        }

        a.button {
          border: 1px solid $brand-primary;
          font-weight: 400;
          padding: 4px 10px;
          font-size: 16px;
          column-gap: 10px;
          display: none;

          @include laptop {
            display: flex;
          }

          &::after {
            content: '';
            position: relative;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 14px;
            height: 14px;
            background-image: url('/../public/arrows-down.webp');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            transform: rotate(-90deg);
            background-color: transparent;
            opacity: 1;
          }

          &:hover,
          &.active {
            border: 1px solid $brand-primary;
            background-color: whitesmoke;
          }
        }
      }
    }
  }
}

li.has-submenu {
  position: relative;
  cursor: pointer;

  .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    box-shadow: 0 2px 2px 3px rgb(0 0 0 / 8%);
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 250px;
    margin-top: 12px;
    padding: 18px;
    flex-direction: column;
    row-gap: 20px;
    display: none;

    li {
      display: block;
      position: relative;

      .tooltip-icon {
        position: absolute;
        right: -6px;
        top: -20px;
        cursor: pointer;
        display: inline-block;

        i {
          margin-left: 5px;
          font-size: 0.9rem;
          color: #cec9c9;
        }

        .custom-tooltip {
          position: absolute;
          top: -35px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fbfbfb;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 10px 14px;
          white-space: nowrap;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
          z-index: 10;
          text-align: center;

          a {
            color: black;
            text-decoration: none;
            font-size: 16px;

            &:hover {
              text-decoration: underline;
              color: #4d3300;
            }
          }
        }
      }

      a {
        color: #333;
        text-decoration: none;
        width: fit-content;
        display: flex;
        column-gap: 7px;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin: 0;
        }

        &:hover {
          color: #4d3300;
        }

        &.active {
          &::after {
            opacity: 0;
            width: 0;
          }

          &:hover::after {
            opacity: 1;
            width: 100%;
          }
        }

        i {
          width: 20px;
        }
      }
    }
  }

  &.open .submenu {
    display: flex;
  }
}

$toggleSize: 40px;
$toggleMargin: 10px;
$toggleLine: 4px;

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.506);
}

.sidenav {
  position: relative;
  display: inline-block;
  // max-width: $toggleSize + $toggleMargin;
  // max-height: $toggleSize + $toggleMargin;
  overflow: visible;
  outline: none;
  position: fixed;
  top: 0;
  z-index: 1;
  transition: .3s ease-in-out;
  right: -300px;
  // height: 100vh;
  // width: 100vw;

  &.open {
    right: 0;

    .nav-toggle {

      &:before,
      &:after {
        box-shadow: none;
      }
    }

    .nav-items {
      transform: translateX(-100%);

      li {
        padding: 0;

        a,
        .menu-item {
          padding: 14px 0 22px;
          border-bottom: 1px solid lighten($brand-primary, 30%);
          display: block;

          transition: 200ms ease-in-out;

          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }

  .nav-items {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    height: 100vh;
    z-index: 1;
    padding: 40px 20px;
    transition: transform .3s ease;
    right: -300px;
    transform: translateX(0);
    left: auto;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px 0;
    align-content: start;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    width: 300px;
    list-style: none;

    li {
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      a {
        font-size: 16px;
        padding: 14px 0 22px;
        border-bottom: 1px solid lighten($brand-primary, 30%);
      }

      a.button {
        border: 1px solid $brand-primary;
        font-weight: 400;
        padding: 4px 10px;
        font-size: 16px;
        column-gap: 10px;
        display: none;

        @include laptop {
          display: flex;
        }

        &::after {
          content: '';
          position: relative;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 14px;
          height: 14px;
          background-image: url('/../public/arrows-down.webp');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          transform: rotate(-90deg);
          background-color: transparent;
          opacity: 1;
        }

        &:hover,
        &.active {
          color: #4d3300;
          border: 1px solid #be7e00;
        }
      }
    }

    li.has-submenu {
      position: relative;
      font-size: 16px;

      .submenu {
        overflow: hidden;
        transform-origin: top;
        max-height: 0;
        /* Initially hidden */
        opacity: 0;
        position: relative;
        flex-direction: column;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;
        top: 0;
        padding: 0;
        margin-top: 0;
        box-shadow: none;
        display: flex;
        background-color: rgb(248, 248, 248);

        li:first-child {
          padding: 18px 18px 18px;
        }

        li {
          padding: 0 18px 18px;
          border-color: lighten($brand-primary, 30%);

          a {
            padding: 0;
            border: none;
            display: flex;
          }
        }

        .tooltip-icon {
          right: 38px;
          top: -10px;

          .custom-tooltip {
            top: -35px;
            left: auto;
            right: 0;
            transform: none;
          }
        }
      }
    }

    li.has-submenu.open .submenu {
      max-height: 500px;
      opacity: 1;
      margin-top: 20px;
    }

    .li-sidenav-button {
      border-bottom: 0;

      a.button {
        display: flex;
        padding: 10px 20px;
        margin-top: 15px;
      }
    }
  }

  .sidenav-logo-link {
    display: block;
  }
}

.hamburger-sidenav-close {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0;

  @include laptop {
    display: none;
  }
}

#hamburger {
  width: 34px;
  height: 34px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: block;
  z-index: 0;

  @include laptop {
    display: none;
  }
}

/* Icon 2 */

#hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: $brand-primary;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburger span:nth-child(even) {
  left: 50%;
  // border-radius: 0 9px 9px 0;
}

#hamburger span:nth-child(odd) {
  left: 0px;
  // border-radius: 9px 0 0 9px;
}

#hamburger span:nth-child(1),
#hamburger span:nth-child(2) {
  top: 0px;
}

#hamburger span:nth-child(3),
#hamburger span:nth-child(4) {
  top: 14px;
}

#hamburger span:nth-child(5),
#hamburger span:nth-child(6) {
  top: 28px;
}

#hamburger.open span:nth-child(1),
#hamburger.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger.open span:nth-child(2),
#hamburger.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#hamburger.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#hamburger.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#hamburger.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#hamburger.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#hamburger.open span:nth-child(5) {
  left: 5px;
  top: 14px;
}

#hamburger.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 14px;
}