@import '../Variables.scss';
@import '../Mixins.scss';

section.why-choose {
  position: relative;
  // overflow-x: hidden;
  padding-bottom: 0;

  @include laptop {
    padding-bottom: 60px;
  }

  &::after {
    content: '';
    background-image: url('../../US-Flag.webp');
    position: absolute;
    z-index: -1;
    inset: 0;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto 0;
    opacity: 0.02;
    background-attachment: fixed;
    width: 80%;

    @include tablet {
      width: 50%;
    }
  }

  .why-choose-title {
    text-align: center;

    @include tablet {
      text-align: left;
    }

    .line-1 {
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 12px;
      margin-left: 2px;
      text-transform: uppercase;
      font-size: 16px;
      justify-content: center;

      @include tablet {
        margin-bottom: 32px;
        margin-left: -70px;
        justify-content: flex-start;
      }

      @include laptop {
        font-size: 20px;
      }

      &::before {
        content: '';
        background-color: $brand-primary;
        width: 60px;
        height: 2px;
        display: none;

        @include desktop {
          display: block;
        }
      }
    }

    .line-2 {
      font-weight: 600;
      display: flex;
      justify-content: center;
      font-size: 30px;

      @include tablet {
        font-size: 40px;
      }

      @include laptop {
        font-size: 50px;
      }

      br {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }
  }

  .col-why-choose-text {
    position: relative;
    text-align: center;

    @include tablet {
      top: 20px;
      text-align: left;
    }

    a {
      margin-top: 40px;
      display: flex;
      column-gap: 10px;
      font-weight: 600;
      position: relative;
      width: fit-content;

      &::after {
        content: '';
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        width: 14px;
        height: 14px;
        background-image: url('/../public/arrows-down.webp');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        transform: rotate(-90deg);
      }
    }
  }

  .why-choose-text {
    position: relative;
    font-size: 16px;

    @include tablet {
      top: 30px;
    }

    @include laptop {
      line-height: 2;
      font-size: 18px;
    }
  }

  a {
    display: inline-block;
    margin: 0 auto;
    font-weight: 500;

    @include tablet {
      margin: 0;
    }
  }

  .features-row {
    margin-top: 60px;
    row-gap: 30px;

    @include tablet {
      margin-top: 100px;
    }

    .feature {
      text-align: center;

      @include tablet {
        text-align: left;
      }

      .feature-icon {
        width: 88px;
        max-height: 64px;
        object-fit: contain;
        object-position: left;
        margin-bottom: 12px;
      }
    }
  }
}

section.home-services {
  overflow-x: hidden;

  .row {
    @include desktop {
      // row-gap: 100px;
    }
  }

  .service-item:last-child {
    margin-bottom: 50px;
  }

  .service-item {
    overflow: hidden;
    // margin-bottom: 100px;

    .row {
      flex-direction: row-reverse;
      position: relative;
      padding: 60px 0;

      @include laptop {
        padding: 0;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -50%;
        background-color: whitesmoke;
        width: 100000px;
        height: 100%;
      }
    }
  }

  .service-item:nth-child(even) {
    .row {
      flex-direction: row;
      overflow: hidden;

      &::after {
        display: none;
      }

      .col-service-image {
        justify-content: flex-start;

        img {
          margin-left: 0;
        }
      }

      .col-service-content {
        position: relative;

        &::after {
          @include laptop {
            content: "";
            position: absolute;
            bottom: 150px;
            left: auto;
            right: 40px;
            background-color: #b77a02;
            width: 40px;
            height: 2px;
            box-shadow: 10px 9px 0 #b77a02;
          }
        }

        &::before {
          @include laptop {
            content: "";
            position: absolute;
            left: auto;
            right: 40px;
            background-color: #b77a02;
            width: 2px;
            height: 36px;
            box-shadow: 10px 9px 0 #b77a02;
            bottom: 150px;
          }
        }

        .card-service-content {
          // padding: 200px 0;

          @include laptop {
            margin-left: 50px;
            margin-right: 50px;
            padding: 200px 0;
          }

          .service-button {
            &:hover {
              background-color: whitesmoke;
            }
          }
        }
      }
    }
  }

  .col-service-image {
    justify-content: flex-end;
    display: flex;
    z-index: 1;
    position: relative;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: -50%;
    //   background-color: whitesmoke;
    //   width: 100%;
    //   height: 100000px;
    // }

    img {
      object-fit: cover;
      z-index: 1;
      height: 100%;
      width: 100%;
      max-height: 45vh;

      @include tablet {
        max-height: 55vh;
      }

      @include laptop {
        // max-height: 65vh;
        height: 100%;
        margin-left: 100px;
      }
    }
  }

  .col-service-content {
    z-index: 1;
    position: relative;
    text-align: center;

    @include laptop {
      text-align: left;
    }

    &::after {
      @include laptop {
        content: "";
        position: absolute;
        bottom: 150px;
        left: 40px;
        background-color: #b77a02;
        width: 40px;
        height: 2px;
        box-shadow: -10px 9px 0 #b77a02;
      }
    }

    &::before {
      @include laptop {
        content: "";
        position: absolute;
        bottom: 150px;
        left: 40px;
        background-color: #b77a02;
        width: 2px;
        height: 36px;
        box-shadow: -10px 9px 0 #b77a02;
      }
    }

    .card-service-content {
      padding: 30px 0 0;

      @include laptop {
        padding: 30px 0;
        margin-left: 100px;
      }

      @include laptop {
        padding: 150px 0;
      }

      @include desktop {
        padding: 200px 0;
      }
    }

    .service-title {
      font-size: 40px;
    }

    .service-button {
      border: 2px solid #b77a02;
      padding: 10px 20px;
      display: block;
      width: fit-content;
      text-align: center;
      transition: 200ms ease-in-out;
      margin: 30px auto 0;

      @include laptop {
        margin: 30px 0 0;
      }

      &:hover {
        background-color: rgb(255, 255, 255);
        color: #8A5C00;
      }
    }
  }
}