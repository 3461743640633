/** Import Bootstrap functions */
$theme-colors: (
  primary: #BF0606
);

$primary-font: "Poppins", sans-serif;
$secondary-font: "Maven Pro", sans-serif;
$michroma: "Michroma", sans-serif;
$font-awesome: 'Font Awesome\ 5 Free';
$brand-primary: #ffba32;
$dark-yellow: #8A5C00;
$brand-secondary: #221F20;

// Nav styles
$nav-top-bg: #221F20;

h1,
h2,
h3,
h4 {
  font-family: $secondary-font;
  font-weight: 700;
}

a {
  font-family: $primary-font;
  transition: 400ms ease;
  color: #8A5C00;

  &:hover {
    text-decoration: none !important;
    color: $brand-primary;
  }
}

// Breakpoint variables (legacy)
$width_mobile: 480px;
$width_phablet: 576px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;
$width_wide: 1440px;
$width_hd: 1600px;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px
);


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);