@import '../Variables.scss';
@import '../Mixins.scss';

.row {
  position: relative;
}

.col-form-info {
  &::after {
    content: '';
    background-image: url('/US-Flag.webp');
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 50%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto 0;
    opacity: 0.02;
    background-attachment: fixed;
    transform: scaleX(-1);
  }
}

.form-info-card {
  position: relative;
  margin-top: 40px;

  @include laptop {
    margin-top: 0;
    margin-left: 40px;
    top: -150px;
  }
}

// Override Ant Design Input and Button border radius
.ant-input,
.ant-input-number,
.ant-input-group-addon,
.ant-btn {
  border-radius: 0 !important; // Remove border radius
}

// Remove border radius for Ant Design Checkbox
.ant-checkbox-inner {
  border-radius: 0 !important;
}

// Remove border radius for Ant Design TextArea
.ant-input-textarea {
  border-radius: 0 !important;
}

// Optional: remove the border radius for Ant Design form item labels too
.ant-form-item-label {
  font-weight: bold;
}

// Increase height for Input fields
.ant-input,
.ant-input-number,
.ant-input-group-addon {
  height: 45px; // Increase the height of the input fields
  font-size: 16px; // Optional: increase font size for better readability
}

.ant-btn {
  height: fit-content !important; // Increase the button height
  font-size: 16px; // Optional: increase font size for buttons
  padding: 10px 20px;
  border: 2px solid #b77a02;
  padding: 10px 16px;
  display: block;
  width: fit-content;
  text-align: center;
  transition: 200ms ease-in-out;
  background-color: transparent;
  color: #8A5C00;
  margin-top: 25px;

  &:hover {
    background-color: rgb(255, 255, 255) !important;
    color: #8A5C00 !important;
  }
}

// Change border color of the input field
.ant-input {
  border-color: #8A5C00 !important; // Change the border color (red in this case)
}

.ant-input:focus {
  border-color: #ebb100 !important; // Change the border color when the input is focused (green in this case)
}

:where(.css-dev-only-do-not-override-49qm).ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label>label {
  font-size: 20px;
}

.ant-input-textarea {
  resize: vertical;
  /* Makes the textarea resizable vertically */
  min-height: 100px;
  /* Sets a minimum height */
  max-height: 300px;
  /* Optionally, set a max height */
}

.ant-checkbox-group.css-dev-only-do-not-override-49qm {
  row-gap: 10px;
}

.form-card {
  background-color: #fafafa;
  padding: 30px;
}

.ant-modal.ant-modal-centered {
  transform-origin: 0 !important;
  transform: none !important;

  @include laptop {
    width: 50% !important;
  }

  .ant-modal-content {
    padding: 50px;
    border-radius: 0;
  }

  .success-image {
    width: 80px;
    margin-bottom: 20px;
  }

  .success-text {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .social-media-text {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .social-media-icon {
    font-size: 28px;
  }
}

.ant-checkbox-group.css-dev-only-do-not-override-49qm {
  display: flex;
  row-gap: 20px;
}

.section-background {
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgb(0 0 0 / 68%) 60%, rgba(0, 0, 0, 0.359));

    @include laptop {
      background: linear-gradient(98deg, rgb(0 0 0 / 68%) 43%, rgb(0 0 0 / 0%));
    }
  }

  iframe {
    width: 133%;
    height: 131%;
    margin-top: -98px;
    margin-left: -11%;
    transform: translateX(8%);
  }
}

.form-group {
  margin-bottom: 20px;
}

textarea {
  padding: 8px 10px;
  min-height: 200px;
}

.error {
  font-size: 15px;
  color: red;
  margin: 6px 0;
}