@import '../Variables.scss';
@import '../Mixins.scss';

.featured-projects {
  position: relative;
  overflow-x: hidden;

  .featured-projects-why-choose-title {
    margin-bottom: 30px;

    .line-1 {
      font-size: 20px;
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-left: 2px;
      text-transform: uppercase;
      margin-left: -70px;

      &::before {
        content: '';
        background-color: $brand-primary;
        width: 60px;
        height: 2px;
      }
    }

    .line-2 {
      font-size: 50px;
      font-weight: 600;
    }
  }

  .swiper {
    @include tablet {
      width: 100vw;
    }

    .swiper-slide {
      .project-card {
        .featured-project-image-link {
          position: relative;
          align-items: center;
          justify-content: center;
          display: flex;
          transition: 400ms ease-in-out;
          z-index: 3;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: 300ms ease-in-out;
          }

          &:hover {
            cursor: pointer;

            &::after {
              background-color: rgba(0, 0, 0, 0.473);
            }

            i {
              visibility: visible;
            }

            .image-count {
              opacity: 1;
            }
          }

          i {
            visibility: hidden;
            position: absolute;
            inset: 0;
            z-index: 1;
            font-size: 50px;
            align-items: center;
            justify-content: center;
            display: flex;
            opacity: 0.7;
            // transition: 100ms ease-in-out;
          }
        }

        .featured-project-image {
          width: 100%;
          height: 329px;
          object-fit: cover;

          @include wide {
            height: 500px;
          }
        }

        .project-name {
          font-weight: 700;
          margin-top: 20px;
          width: 78%;
        }

        .image-count {
          width: 12%;
          position: absolute;
          right: 20px;
          top: 20px;
          font-weight: 600;
          font-size: 30px;
          color: white;
          z-index: 1;
          line-height: 1;
          text-transform: uppercase;
          opacity: 0;
          transition: 300ms ease-in-out;
        }
      }
    }
  }

  .custom-navigation {
    display: flex;
    position: relative;
    justify-content: flex-end;


    gap: 43px;
    left: -8px;
    margin-top: 40px;
    margin-bottom: 20px;

    @include tablet {
      margin: 0;
      margin-top: 40px;
      left: 0;
      gap: 55px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(0, 0, 0, 0);
    color: #515151;
    border: none;
    padding: 0 10px;
    height: fit-content;
    line-height: 1;
    cursor: pointer;
    position: relative;
    font-size: 30px;

    @include tablet {
      font-size: 40px;
    }

    &::after {
      display: none;
    }
  }

  .featured-projects-button {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      margin: 20px auto 0;
      display: flex;
      width: fit-content;
      border: 1px solid $brand-primary;
      font-weight: 400;
      padding: 8px 20px;
      column-gap: 10px;

      @include tablet {
        margin: 0 auto;
      }

      &::after {
        content: '';
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        width: 14px;
        height: 14px;
        background-image: url('/../public/arrows-down.webp');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        transform: rotate(-90deg);
        background-color: transparent;
        opacity: 1;
      }
    }
  }
}