@import './Variables.scss';
@import './Mixins.scss';

html {
  scroll-behavior: auto !important;
  font-size: 16px;
  width: 100%;
  // overflow-x: hidden;
  clip-path: inset(0 0 0 0);

  @include tablet {
    font-size: 20px;
  }

  // * {
  //   box-sizing: border-box;
  // }
}

.site-container {
  margin-top: 80px;

  @include tablet {
    margin-top: 110px;
  }

  @include desktop {
    margin-top: 123px;
  }
}

.card {
  border-radius: 50px;
  filter: drop-shadow(0px 59px 55.5px rgba(154, 155, 176, 0.17));
  background-color: white;
  padding: 20px;
  margin: 10px 0;
}

.button {
  font-weight: 600;
}

.alert-modal-content {
  max-width: 80vw;

  @include wide {
    max-width: 1300px;
  }
}

.button.alert-btn {
  background-color: darken($brand-primary, 20);
  color: white;

  &:hover {
    color: white;
    background-color: $brand-primary;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 254px;
}

body a {
  text-decoration: none !important;
  color: #4d3300;
}

body {
  scroll-behavior: auto !important;
  overflow-x: hidden;
}

.breadcrumbs {
  display: flex;
  column-gap: 10px;
  font-size: 16px;
}

.page-card {
  background-color: #fafafa;
  padding: 12px 8px;

  @include tablet {
    padding: 25px 35px;
  }
}

.main-page-content {
  img {
    margin: 30px auto;
  }

  p {
    line-height: 1.8;
    margin: 10px 0 40px;
  }
}

.row.row-sidebar-page {
  flex-wrap: nowrap;
}

.col-9.col-sidebar-page {
  width: auto;
  flex: 1;
}

.panel {
  p a {
    color: black;
    text-decoration: underline !important;
    text-underline-offset: 3px;
    text-decoration-color: #f4c200 !important;
    text-decoration-thickness: 2px !important;

    &:hover {
      color: $dark-yellow;
    }
  }
}

.panel {
  padding: 30px 0;

  @include tablet {
    padding: 60px 0;
  }
}

img {
  max-width: 100%;
  object-fit: cover;
  height: auto;
}

button.button {
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
  transition: 200ms ease-in-out;
  padding: 6px 20px;

  &:hover {
    color: #8A5C00;
  }
}

.button {
  position: relative;
  display: flex;
  column-gap: 10px;
  z-index: 3;
  width: fit-content;
  font-weight: 500;

  &::after {
    content: '';
    position: relative;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 14px;
    height: 14px;
    background-image: url('/../public/white-arrows.webp');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    transform: rotate(-90deg);
  }

  &:hover {
    color: #8A5C00;
  }
}

/* src/styles.css */

.not-found {
  text-align: center;
  padding: 50px;
}

.not-found h1 {
  font-size: 36px;
  color: #ff0000;
}

.not-found p {
  font-size: 18px;
  color: #333;
}

.search-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.contact-hours {
  .hours {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    margin: 15px 0;

    span {
      width: 100%;
      opacity: 0;
      height: 0;
    }
  }
}