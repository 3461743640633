@import '../Variables.scss';
@import '../Mixins.scss';

.wp-block-gallery {
  display: flex;
  column-gap: 20px;
}

.interior-page-section.small-section,
.interior-page-section-contained.small-section {
  min-height: 70vh;
}

.interior-page-section {
  position: relative;
  color: white;
  margin-top: 0;
  min-height: 70vh;

  @include laptop {
    min-height: 100vh;
  }

  .section-background {
    background-size: cover;
    background-position: center;
    position: absolute;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    left: 50%;
    height: 100%;
    z-index: -1;
    top: 0;
    background-color: rgba(0, 0, 0, 0.333);
    background-blend-mode: overlay;
  }

  .container {
    padding: 50px 0;
    margin: 0;
    width: 100%;

    @include tablet {
      width: 50%;
    }

    a {
      color: $brand-primary;

      &:hover {
        color: #fff;
      }
    }
  }
}

.interior-page-section-contained {
  position: relative;
  color: white;
  margin: 50px 0;
  min-height: 100vh;

  @include laptop {
    min-height: 50vh;
  }

  .section-background {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    z-index: -1;
    top: 0;
    background-color: rgba(0, 0, 0, 0.105);
    background-blend-mode: overlay;
  }

  .container {
    width: 100%;
    margin: 0;
    padding: 30px;

    @include tablet {
      width: 50%;
      padding: 50px;
    }

    a {
      color: $brand-primary;

      &:hover {
        color: #fff;
      }
    }
  }
}