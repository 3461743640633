@import '../Variables.scss';
@import '../Mixins.scss';

.container-projects-content,
.container-projects-content .container {
  @include wide {
    // max-width: 1450px;
  }
}

.projects {
  >.row {
    justify-content: flex-start;
    row-gap: 30px;
    align-items: flex-start;
  }

  // .col-project {}

  .project-card {
    .project-image-link {
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      transition: 400ms ease-in-out;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.327);
        transition: 300ms ease-in-out;
      }

      &:hover {
        cursor: pointer;

        i {
          visibility: visible;
        }

        .project-image {
          transform: scale(1.05);
        }

        .image-count {
          opacity: 1;
        }
      }

      i {
        visibility: hidden;
        position: absolute;
        inset: 0;
        z-index: 1;
        font-size: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
        opacity: 0.5;
        // transition: 100ms ease-in-out;
      }

      .project-image {
        transition: 400ms ease-in-out;
      }

      .project-name {
        width: 78%;
        position: absolute;
        left: 20px;
        top: 20px;
        font-weight: 600;
        font-size: 30px;
        color: white;
        z-index: 1;
        line-height: 1;
        text-transform: uppercase;
      }

      .image-count {
        width: 12%;
        position: absolute;
        right: 20px;
        top: 20px;
        font-weight: 600;
        font-size: 30px;
        color: white;
        z-index: 1;
        line-height: 1;
        text-transform: uppercase;
        opacity: 0;
        transition: 300ms ease-in-out;
      }
    }

    .glightbox-thumbnail {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 1px solid #ccc;
      margin: 5px;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 50vh;

      @include tablet {
        height: 80vh;
        max-height: 600px;
      }
    }
  }
}