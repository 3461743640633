.slider-mask {
  position: relative;
  overflow: hidden;
}

.AB-slidebar {
  position: relative;
}

.AB-slidebar,
.background-image-bef,
.background-image-aft {
  height: 400px;
  width: 550px;
}

.slider-bef,
.slider-aft {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  .background-image-bef,
  .background-image-aft {
    background-size: cover;
    // position: absolute;
  }
}

.slider-bef {
  z-index: 2;
  overflow: hidden;
}

.slider-aft {
  z-index: 1;
}

.handle-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: ew-resize;
  z-index: 3;
}

.slider-handle {
  width: 10px;
  height: 100%;
  display: block;
  background-color: #fff;
}